import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { emptyList, fetchBlog, updateBlog } from "../../store/blog/blogSlice";
import ReactScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet-async";

function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    list: { docs = [] },
    list,
    loading,
  } = useSelector((state) => state?.blog);
  const total = list.totalDocs;

  useEffect(() => {
    dispatch(emptyList());
    dispatch(
      fetchBlog({
        limit: 12,
        page: 1,
      })
    );
  }, []);

  const onSubmit = (slug) => {
    dispatch(updateBlog(slug))
      .unwrap()
      .then((data) => {
        if (data?.status) {
          navigate(`/blog/${slug}`);
        }
      });
  };

  function onLoadMoreProducts() {
    if (list.nextPage) {
      dispatch(
        fetchBlog({
          limit: 12,
          page: list.nextPage,
        })
      );
    }
  }

  return (
    <>
      <Helmet>
        <title>{"Jane anjani baten - Blogs"}</title>
        <meta name="description" content={"All Blogs"} />
      </Helmet>

      <div role="main" className="main pt-3 mt-3">
        <div className="container">
          <div className="row pb-1">
            {loading ? (
              <div className="col-lg-7 mb-4 pb-2">
                <article className="thumb-info thumb-info-no-borders thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
                  <Skeleton containerClassName="img-fluid" height={350} />
                </article>
              </div>
            ) : (
              docs?.length > 0 && (
                <div className="col-lg-7 mb-4 pb-2">
                  <Link onClick={() => onSubmit(docs.length && docs[0].slug)}>
                    <article className="thumb-info thumb-info-no-borders thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
                      <div className="thumb-info-wrapper thumb-info-wrapper-opacity-6">
                        <img
                          src={docs.length && docs[0]?.image}
                          // className="img-fluid"
                          width={"100%"}
                          height={350}
                          style={{
                            objectFit: "cover",
                            objectPosition: "top center",
                          }}
                          alt=""
                        />
                        <div className="thumb-info-title bg-transparent p-4">
                          <div className="thumb-info-type bg-color-dark px-2 mb-1">
                            {docs.length && docs[0].category.name}
                          </div>
                          <div className="thumb-info-inner mt-1">
                            <h2 className="font-weight-bold text-color-light line-height-2 text-5 mb-0">
                              {docs.length && docs[0].title}
                            </h2>
                          </div>
                          <div className="thumb-info-show-more-content">
                            <p className="mb-0 text-1 line-height-9 mb-1 mt-2 text-light opacity-5">
                              {docs.length && docs[0].short_description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </article>
                  </Link>
                </div>
              )
            )}
            {loading ? (
              <div className="col-lg-5">
                {new Array(parseInt(3)).fill(1).map((item, index) => (
                  <article
                    key={index}
                    className="thumb-info thumb-info-no-zoom bg-transparent border-radius-0 pb-2 mb-2"
                  >
                    <div className="row align-items-center pb-1">
                      <div className="col-sm-5">
                        <Skeleton height="100px" />
                      </div>
                      <div className="col-sm-7 ps-sm-1">
                        <div className="thumb-info-caption-text">
                          <Skeleton width={60} height={20} />
                          <Skeleton count={1} />
                        </div>
                      </div>
                    </div>
                  </article>
                ))}
              </div>
            ) : (
              <div className="col-lg-5">
                {docs?.length > 0 &&
                  docs.slice(1, 4).map((item, index) => {
                    return (
                      <article
                        key={index}
                        className="thumb-info thumb-info-no-zoom bg-transparent border-radius-0 pb-4 mb-2"
                      >
                        <div className="row align-items-center pb-1">
                          <div className="col-sm-5">
                            <Link onClick={() => onSubmit(item.slug)}>
                              <img
                                src={item?.image}
                                // className="img-fluid border-radius-0"
                                className="border-radius-0"
                                width={"100%"}
                                height={100}
                                style={{
                                  objectFit: "cover",
                                  objectPosition: "top center",
                                }}
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="col-sm-7 ps-sm-1">
                            <div className="thumb-info-caption-text">
                              <div className="thumb-info-type text-light text-uppercase d-inline-block bg-color-dark px-2 m-0 mb-1 float-none">
                                <Link
                                  onClick={() => onSubmit(item.slug)}
                                  className="text-decoration-none text-color-light"
                                >
                                  {item?.category?.name}
                                </Link>
                              </div>
                              <h2 className="d-block line-height-2 text-4 text-dark font-weight-bold mt-1 mb-0">
                                <Link
                                  onClick={() => onSubmit(item.slug)}
                                  className="text-decoration-none text-color-dark text-color-hover-primary"
                                >
                                  {item.title}
                                </Link>
                              </h2>
                            </div>
                          </div>
                        </div>
                      </article>
                    );
                  })}
              </div>
            )}
          </div>
          <ReactScroll
            dataLength={docs ? docs.length : 5}
            scrollThreshold={"90%"}
            next={onLoadMoreProducts}
            hasMore={total && docs && total > docs.length ? true : false}
            style={{ overflow: "visible", position: "relative" }}
            loader={
              <div className="loader">
                <div className="bounce-loader">
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              </div>
            }
          >
            <div className="row pb-1">
              {loading
                ? new Array(parseInt(12)).fill(1).map((item, index) => (
                    <div key={index} className="col-sm-6 col-lg-4 mb-4 pb-2">
                      <Skeleton containerClassName="img-fluid" height={220} />
                    </div>
                  ))
                : docs?.length > 0 &&
                  docs.map((item, index) => {
                    return (
                      <div key={index} className="col-sm-6 col-lg-4 mb-4 pb-2">
                        <Link onClick={() => onSubmit(item.slug)}>
                          <article>
                            <div className="thumb-info thumb-info-no-borders thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
                              <div className="thumb-info-wrapper thumb-info-wrapper-opacity-6">
                                <img
                                  src={item?.image}
                                  // className="img-fluid"
                                  width={"100%"}
                                  height={200}
                                  style={{
                                    objectFit: "cover",
                                    objectPosition: "top center",
                                  }}
                                  alt=""
                                />
                                <div className="thumb-info-title bg-transparent p-4">
                                  <div className="thumb-info-type bg-color-primary px-2 mb-1">
                                    {item?.category?.name}
                                  </div>
                                  <div className="thumb-info-inner mt-1">
                                    <h2 className="text-color-light line-height-2 text-4 font-weight-bold mb-0">
                                      {item.title}
                                    </h2>
                                  </div>
                                  <div className="thumb-info-show-more-content">
                                    <p className="mb-0 text-1 line-height-9 mb-1 mt-2 text-light opacity-5">
                                      {item.short_description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </article>
                        </Link>
                      </div>
                    );
                  })}
            </div>
            <div className="row pb-1">
              {!loading && docs.length === 0 ? (
                <div className="info-box with-icon">
                  <p>No blogs found ....</p>
                </div>
              ) : (
                ""
              )}
            </div>
          </ReactScroll>
        </div>
      </div>
    </>
  );
}

export default Index;
