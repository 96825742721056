import { Link } from "react-router-dom";

function Index() {
  return (
    <footer id="footer" className="mt-0">
      <div className="footer-copyright">
        <div className="container py-2">
          <div className="row py-4">
            <div className="col-lg-2 d-flex align-items-center justify-content-center justify-content-lg-start mb-2 mb-lg-0">
              <Link
                to="/"
                className="logo text-white text-decoration-none fw-bold fs-5"
              >
                {/* <img
                  alt="Porto Website Template"
                  src="/assets/img/logo-footer.png"
                  className="opacity-5"
                  height="32"
                /> */}
                Jane anjani baten
              </Link>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-start mb-4 mb-lg-0">
              <p>© Copyright 2023. All Rights Reserved.</p>
            </div>
            <div className="col-lg-4 d-flex align-items-center justify-content-center justify-content-lg-end">
              <nav id="sub-menu">
                <ul>
                  <li>
                    <i className="fas fa-angle-right"></i>
                    <Link to="/privacy" className="ms-1 text-decoration-none">
                      {" "}
                      Privacy
                    </Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                    <Link to="/about-us" className="ms-1 text-decoration-none">
                      {" "}
                      About us
                    </Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                    <Link
                      to="/contact-us"
                      className="ms-1 text-decoration-none"
                    >
                      {" "}
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Index;
