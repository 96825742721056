import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBlog,
  fetchBlogBySlug,
  popularBlog,
  updateBlog,
} from "../../store/blog/blogSlice";
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet-async";

function BlogDetail() {
  let { slug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    list: { docs = [] },
    data,
    popular,
    loading,
    error,
  } = useSelector((state) => state.blog);

  useEffect(() => {
    if (slug) {
      dispatch(fetchBlogBySlug(slug))
        .unwrap()
        .then((data) => {
          if (data?.status) {
            dispatch(
              fetchBlog({
                limit: 4,
                page: 1,
              })
            );
            dispatch(popularBlog(slug));
          }
        });
    }
  }, [slug]);

  if (error) {
    return <div>{error?.message}</div>;
  }

  if (!slug) {
    return <div>Record not found</div>;
  }

  const onSubmit = (slug) => {
    dispatch(updateBlog(slug))
      .unwrap()
      .then((data) => {
        if (data?.status) {
          navigate(`/blog/${slug}`);
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>{data.title}</title>
        <meta name="description" content={data.short_description} />
        <meta property="og:title" content={data.title} />
        <meta property="og:description" content={data.short_description} />
        <meta property="og:image" content={data.image} />
        <meta property="og:url" content={`https://blog-taupe-five-29.vercel.app/blog/${data.slug}`} />
        <meta property="og:type" content="article,blog" />
        
      </Helmet>
      <section className="page-header page-header-modern bg-color-grey page-header-md mb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12 align-self-center p-static order-2 text-center">
              <h1 className="text-dark font-weight-bold text-8">Blog Detail</h1>
            </div>
            <div className="col-md-12 align-self-center order-1">
              <ul className="breadcrumb d-block text-center">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Blog Detail</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container py-4">
        <div className="row">
          <div className="col-lg-3 order-2">
            <div className="row pb-1">
              <div className="col-md-12">
                <h3 className="font-weight-bold text-3 mb-0">Popular Posts</h3>
                <ul className="simple-post-list">
                  {loading
                    ? new Array(parseInt(4)).fill(1).map((item, index) => (
                        <li key={index}>
                          <article key={index} className="d-flex">
                            <div>
                              <Skeleton height="70px" width={70} />
                            </div>
                            <div className="post-info ms-2 w-100">
                              <Skeleton width={60} height={10} />
                              <Skeleton count={1} height={15} width={"90%"} />
                            </div>
                          </article>
                        </li>
                      ))
                    : popular?.length > 0 &&
                      popular.map((item, index) => {
                        return (
                          <li key={index}>
                            <article>
                              <div className="post-image">
                                <div className="img-thumbnail img-thumbnail-no-borders d-block">
                                  <a href="blog-post.html">
                                    <img
                                      src={item?.image}
                                      className="border-radius-0"
                                      width="70"
                                      height="70"
                                      alt=""
                                    />
                                  </a>
                                </div>
                              </div>
                              <div className="post-info">
                                <div className="post-meta">
                                  {moment(item.created).format("MMMM DD, YYYY")}
                                </div>
                                <h4 className="font-weight-normal text-3 mb-0">
                                  <Link
                                    onClick={() => onSubmit(item.slug)}
                                    className="text-dark"
                                  >
                                    {item.title}
                                  </Link>
                                </h4>
                              </div>
                            </article>
                          </li>
                        );
                      })}
                </ul>
              </div>
              <div className="col-md-12">
                <h3 className="font-weight-bold text-3 mb-0 mt-3">
                  Recent Posts
                </h3>
                <ul className="simple-post-list">
                  {loading
                    ? new Array(parseInt(4)).fill(1).map((item, index) => (
                        <li key={index}>
                          <article key={index} className="d-flex">
                            <div>
                              <Skeleton height="70px" width={70} />
                            </div>
                            <div className="post-info ms-2 w-100">
                              <Skeleton width={60} height={10} />
                              <Skeleton count={1} height={15} width={"90%"} />
                            </div>
                          </article>
                        </li>
                      ))
                    : docs?.length > 0 &&
                      docs
                        .filter((val) => val?.slug !== slug)
                        .slice(0, 4)
                        .map((item, index) => {
                          return (
                            <li key={index}>
                              <article>
                                <div className="post-image">
                                  <div className="img-thumbnail img-thumbnail-no-borders d-block">
                                    <a href="blog-post.html">
                                      <img
                                        src={item?.image}
                                        className="border-radius-0"
                                        width="70"
                                        height="70"
                                        alt="Tips to Help You Quickly Prepare your Lunch"
                                      />
                                    </a>
                                  </div>
                                </div>
                                <div className="post-info">
                                  <div className="post-meta">
                                    {moment(item.created).format(
                                      "MMMM DD, YYYY"
                                    )}
                                  </div>
                                  <h4 className="font-weight-normal text-3 mb-0">
                                    <Link
                                      onClick={() => onSubmit(item.slug)}
                                      className="text-dark"
                                    >
                                      {item.title}
                                    </Link>
                                  </h4>
                                </div>
                              </article>
                            </li>
                          );
                        })}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-9 order-1">
            <div className="blog-posts single-post">
              <article className="post post-large blog-single-post border-0 m-0 p-0">
                <div className="post-image ms-0">
                  {loading ? (
                    <Skeleton containerClassName="img-fluid" height={350} />
                  ) : (
                    <img
                      src={data?.image}
                      width={"100%"}
                      className="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0"
                      alt=""
                    />
                  )}
                </div>

                <div className="post-date ms-0">
                  <span className="day">
                    {loading ? (
                      <Skeleton width={40} height={10} />
                    ) : (
                      moment(data?.created).format("DD")
                    )}
                  </span>
                  <span className="month">
                    {" "}
                    {loading ? (
                      <Skeleton width={40} height={10} />
                    ) : (
                      moment(data?.created).format("MMM")
                    )}
                  </span>
                </div>

                <div className="post-content ms-0">
                  <h2 className="font-weight-semi-bold text-primary">
                    {data?.title}
                  </h2>

                  <div className="post-meta">
                    <span>
                      <i className="far fa-user"></i> By{" "}
                      <span className="text-primary"> {data?.post_by} </span>
                    </span>
                    <span>
                      <i className="far fa-folder"></i>{" "}
                      <span className="text-primary">
                        {data?.category?.name}
                      </span>
                    </span>
                  </div>

                  <p
                    className="blog-description-container"
                    dangerouslySetInnerHTML={{ __html: data?.description }}
                  />
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogDetail;
