// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const API_URL = `${process.env.REACT_APP_API_URL}/api`;

// ** create Contact Us
export const createContactUs = createAsyncThunk(
  "contact/createContactUs",
  async (payload) => {
    try {
      const response = await fetch(`${API_URL}/contact`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.status) {
        return data;
      }
      throw new Error(data?.message);
    } catch (error) {
      toast.error(error.message);
      throw new Error(error.message);
    }
  }
);

export const contactSlice = createSlice({
  name: "contact",
  initialState: {
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createContactUs.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createContactUs.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createContactUs.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default contactSlice.reducer;
