import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createContactUs } from "../../store/contact/contactSlice";
import { Helmet } from "react-helmet-async";

function Index() {
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const { loading } = useSelector((state) => state?.contact);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      email: Yup.string().email().required("Required"),
      subject: Yup.string().required("Required"),
      message: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      setIsSuccess(false);
      dispatch(createContactUs(values))
        .unwrap()
        .then((data) => {
          if (data?.status) {
            setIsSuccess(true);
            formik.resetForm();
          }
        });
    },
  });

  return (
    <>
      <Helmet>
        <title>{"Jane anjani baten - Contact Us"}</title>
        <meta name="description" content={"Contact Us"} />
      </Helmet>
      <section className="page-header page-header-modern bg-color-grey page-header-md mb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12 align-self-center p-static order-2 text-center">
              <h1 className="text-dark font-weight-bold text-8">Contact Us </h1>
            </div>
            <div className="col-md-12 align-self-center order-1">
              <ul className="breadcrumb d-block text-center">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Contact Us </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row justify-content-center py-4">
          <div className="col-lg-6">
            {/* <h2 className="font-weight-bold text-8 mt-2 mb-0">Contact Us</h2> */}
            {/* <p className="mb-4">
              Feel free to ask for details, don't save any questions!
            </p> */}

            <form className="contact-form" onSubmit={formik.handleSubmit}>
              {isSuccess && (
                <div className="contact-form-success alert alert-success mt-4">
                  <strong>Success!</strong> Your message has been sent to us.
                </div>
              )}
              <div className="contact-form-error alert alert-danger d-none mt-4">
                <strong>Error!</strong> There was an error sending your message.
                <span className="mail-error-message text-1 d-block"></span>
              </div>
              <div className="row">
                <div className="form-group col-lg-6">
                  <label className="form-label mb-1 text-2">Full Name</label>
                  <input
                    type="text"
                    maxLength="100"
                    className="form-control text-3 h-auto py-2"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div style={{ color: "red" }}>{formik.errors.name}</div>
                  ) : null}
                </div>
                <div className="form-group col-lg-6">
                  <label className="form-label mb-1 text-2">
                    Email Address
                  </label>
                  <input
                    type="email"
                    maxLength="100"
                    className="form-control text-3 h-auto py-2"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div style={{ color: "red" }}>{formik.errors.email}</div>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="form-group col">
                  <label className="form-label mb-1 text-2">Subject</label>
                  <input
                    type="text"
                    maxLength="100"
                    className="form-control text-3 h-auto py-2"
                    name="subject"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.subject}
                  />
                  {formik.touched.subject && formik.errors.subject ? (
                    <div style={{ color: "red" }}>{formik.errors.subject}</div>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="form-group col">
                  <label className="form-label mb-1 text-2">Message</label>
                  <textarea
                    maxLength="5000"
                    data-msg-required="Please enter your message."
                    rows="8"
                    className="form-control text-3 h-auto py-2"
                    name="message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  ></textarea>
                  {formik.touched.message && formik.errors.message ? (
                    <div style={{ color: "red" }}>{formik.errors.message}</div>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="form-group col">
                  <button
                    type="submit"
                    value={``}
                    className="btn btn-primary btn-modern"
                    data-loading-text="Loading..."
                    disabled={loading}
                  >
                    Send Message{" "}
                    {loading && (
                      <div
                        className="spinner-border spinner-border-sm ms-2 "
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
