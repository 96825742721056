import { HelmetProvider } from "react-helmet-async";
import Root from "./root";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <HelmetProvider>
      <Root />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="light"
      />
      <ToastContainer />
    </HelmetProvider>
  );
}

export default App;
