import { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import Privacy from "./pages/Privacy";
import Error404 from "./pages/404";

function Router() {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogDetail />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path='*' exact={true} element={<Error404 />} />
      </Routes>
    </Fragment>
  );
}

export default Router;
