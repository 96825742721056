import { Link } from "react-router-dom";

function Index() {
  return (
    <header id="header" style={{ height: 80 }}>
      <div className="header-body border-color-primary border-top-0 box-shadow-none  bg-primary">
        <div
          className="header-container container z-index-2 "
          style={{ minHeight: 60 }}
        >
          <div className="header-row ">
            <div className="header-column justify-content-center">
              <div className="header-row">
                <h1 className="header-logo">
                  <Link
                    to="/"
                    className="text-white text-decoration-none fw-bold"
                  >
                    {/* <img
                      alt="Porto"
                      width="100"
                      height="48"
                      src="/assets/img/logo.jpeg"
                    /> */}
                    Jane anjani baten
                  </Link>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Index;
