// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import toast from "react-hot-toast";

const API_URL = `${process.env.REACT_APP_API_URL}/api`;

// ** fetch Blog

export const fetchBlog = createAsyncThunk("blog/fetchBlog", async (payload) => {
  let filter = [];
  if (payload && payload.category) filter.push(`category=${payload.category}`);
  if (payload && payload.limit) filter.push(`limit=${payload.limit}`);
  if (payload && payload.page) filter.push(`page=${payload.page}`);

  try {
    const response = await fetch(`${API_URL}/post?${filter.join("&")}`);
    const data = await response.json();
    if (data.status) {
      return data.data;
    }
    throw new Error(data?.message);
  } catch (error) {
    // toast.error(error.message);
    throw new Error(error.message);
  }
});

// ** fetch Blog By Slug
export const fetchBlogBySlug = createAsyncThunk(
  "blog/fetchBlogBySlug",
  async (payload) => {
    try {
      const response = await fetch(`${API_URL}/post/` + payload);
      const data = await response.json();
      if (data.status) {
        return data;
      }
      throw new Error(data?.message);
    } catch (error) {
      // toast.error(error.message);
      throw new Error(error.message);
    }
  }
);

// ** popular blog
export const popularBlog = createAsyncThunk(
  "blog/popularBlog",
  async (payload) => {
    try {
      const response = await fetch(`${API_URL}/post/popularBlog/` + payload);
      const data = await response.json();
      if (data.status) {
        return data;
      }
      throw new Error(data?.message);
    } catch (error) {
      // toast.error(error.message);
      throw new Error(error.message);
    }
  }
);

export const updateBlog = createAsyncThunk(
  "blog/updateBlog",
  async (payload) => {
    try {
      const response = await fetch(`${API_URL}/post/blogIncrement/` + payload);
      const data = await response.json();
      if (data.status) {
        return data;
      }
      throw new Error(data?.message);
    } catch (error) {
      // toast.error(error.message);
      throw new Error(error.message);
    }
  }
);

export const blogSlice = createSlice({
  name: "blog",
  initialState: {
    list: { docs: [] },
    data: {},
    popular: [],
    loading: false,
    error: "",
  },
  reducers: {
    emptyList: (state, action) => {
      state.list = {
        docs: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBlog.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchBlog.fulfilled, (state, action) => {
      state.list = {
        ...action.payload,
        docs: [...state.list.docs, ...action.payload.docs],
      };
      state.loading = false;
    });
    builder.addCase(fetchBlog.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(fetchBlogBySlug.pending, (state, action) => {
      state.isLoader = true;
    });
    builder.addCase(fetchBlogBySlug.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.isLoader = false;
    });
    builder.addCase(fetchBlogBySlug.rejected, (state, action) => {
      state.isLoader = false;
      state.error = action.error;
    });
    builder.addCase(popularBlog.pending, (state, action) => {
      state.isLoader = true;
    });
    builder.addCase(popularBlog.fulfilled, (state, action) => {
      state.popular = action.payload?.data;
      state.isLoader = false;
    });
    builder.addCase(popularBlog.rejected, (state, action) => {
      state.isLoader = false;
      state.error = action.error;
    });
  },
});

export const { emptyList } = blogSlice.actions;

export default blogSlice.reducer;
