// ** Toolkit imports
import { configureStore } from "@reduxjs/toolkit";

// ** Reducers
import contactReducer from "./contact/contactSlice";
import blogReducer from "./blog/blogSlice";

export const store = configureStore({
  reducer: {
    contact: contactReducer,
    blog: blogReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
