import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLayoutEffect, Suspense } from "react";
import Router from "../router";

function Index() {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
    }, [location.pathname]);
    return children;
  };

  return (
    <Wrapper>
      <Suspense fallback={<div></div>}>
        <div className="body">
          <Header />
          <Router />
          <Footer />
        </div>
      </Suspense>
    </Wrapper>
  );
}

export default Index;
