import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

function Index() {
  return (
    <>
      <Helmet>
        <title>{"Jane anjani baten - About Us"}</title>
        <meta name="description" content={"About Us"} />
      </Helmet>
      <section className="page-header page-header-modern bg-color-grey page-header-md mb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12 align-self-center p-static order-2 text-center">
              <h1 className="text-dark font-weight-bold text-8">About Us </h1>
            </div>
            <div className="col-md-12 align-self-center order-1">
              <ul className="breadcrumb d-block text-center">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">About Us </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row pt-5">
          <div className="col">
            <p>
              Your daily destination for intriguing mysteries and timely
              updates!
            </p>
            <p>
              We believe that every day holds a new story waiting to be
              uncovered, and mysteries that beg to be unraveled. Our passion for
              discovery and fascination with the unknown drive us to bring you
              engaging content that captivates your imagination.
            </p>
            <p>
              <strong>What Sets Us Apart:</strong>
            </p>
            <p>
              <b>Daily Updates:</b> We understand that the world is constantly
              evolving, and so are our interests. That's why we commit ourselves
              to deliver fresh and exciting content every single day. From the
              latest happenings to timeless mysteries, we've got your daily dose
              of curiosity covered.
            </p>
            <p>
              <b>Unveiling the Unseen:</b> Dive into the realm of mysteries with
              us as we explore the unexplained, the enigmatic, and the downright
              mysterious. Our team of passionate writers is dedicated to
              shedding light on the unknown, providing you with
              thought-provoking narratives that will keep you on the edge of
              your seat.
            </p>
            <p>
              <strong>Our Mission:</strong>
            </p>
            <p>
              Our mission is to inspire wonder and spark curiosity. We aim to
              create a community of like-minded individuals who share a love for
              daily updates and a fascination with mysteries. Through our
              carefully curated content, we hope to not only entertain but also
              encourage conversations and exploration.
            </p>
            <p>
              {" "}
              <strong>Who We Are:</strong>
            </p>
            <p>
              {" "}
              We are a team of dedicated writers, researchers, and enthusiasts
              who are committed to bringing you the best in daily updates and
              mystery content. Our diverse backgrounds and interests converge to
              create a dynamic and exciting platform that caters to a broad
              audience.{" "}
            </p>
            <p>
              <strong>Join Us on the Journey:</strong>
            </p>
            <p>
              {" "}
              Whether you're a seasoned mystery enthusiast or someone who simply
              enjoys staying informed with daily updates, Welcomes you to join
              us on this thrilling journey. Subscribe, engage, and be part of a
              community that shares your passion for the mysterious and the
              ever-changing world around us.
            </p>
            <p>
              Thank you for being part of the my family. Get ready to explore,
              discover, and uncover the mysteries that await!
            </p>
            {/* <div className="row text-center pb-5">
              <div className="col-md-9 mx-md-auto">
                <div className="overflow-hidden mb-3">
                  <h1 className="word-rotator slide font-weight-bold text-8 mb-0">
                    <span>We are Porto, We </span>
                    <span className="word-rotator-words bg-primary">
                      <b className="is-visible">Create</b>
                      <b>Build</b>
                      <b>Develop</b>
                    </span>
                    <span> Solutions</span>
                  </h1>
                </div>
                <div className="overflow-hidden mb-3">
                  <p className="lead mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Fusce elementum, nulla vel pellentesque consequat, ante
                    nulla hendrerit arcu, ac tincidunt mauris lacus sed leo.
                  </p>
                </div>
              </div>
            </div>

            <div className="row mt-3 mb-5">
              <div className="col-md-4">
                <h3 className="font-weight-bold text-4 mb-2">Our Mission</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                  elementum, nulla vel pellentesque consequat, ante nulla
                  hendrerit arcu.
                </p>
              </div>
              <div className="col-md-4">
                <h3 className="font-weight-bold text-4 mb-2">Our Vision</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                  nulla vel pellentesque consequat, ante nulla hendrerit arcu.
                </p>
              </div>
              <div className="col-md-4">
                <h3 className="font-weight-bold text-4 mb-2">Why Us</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                  elementum, nulla vel consequat, ante nulla hendrerit arcu.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <section className="section section-primary border-0 mb-0 ">
        <div className="container">
          <div className="row counters counters-sm pb-4 pt-3">
            <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
              <div className="counter">
                <i className="icons icon-user text-color-light"></i>
                <strong
                  className="text-color-light font-weight-extra-bold"
                  data-to="45000"
                  data-append="+"
                >
                  0
                </strong>
                <label className="text-4 mt-1 text-color-light">
                  Happy Clients
                </label>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
              <div className="counter">
                <i className="icons icon-badge text-color-light"></i>
                <strong
                  className="text-color-light font-weight-extra-bold"
                  data-to="15"
                >
                  0
                </strong>
                <label className="text-4 mt-1 text-color-light">
                  Years In Business
                </label>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mb-5 mb-sm-0">
              <div className="counter">
                <i className="icons icon-graph text-color-light"></i>
                <strong
                  className="text-color-light font-weight-extra-bold"
                  data-to="178"
                >
                  0
                </strong>
                <label className="text-4 mt-1 text-color-light">
                  High Score
                </label>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="counter">
                <i className="icons icon-cup text-color-light"></i>
                <strong
                  className="text-color-light font-weight-extra-bold"
                  data-to="352"
                >
                  0
                </strong>
                <label className="text-4 mt-1 text-color-light">
                  Cups of Coffee
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-height-3 bg-color-grey m-0 border-0">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 pb-sm-4 pb-lg-0 pe-lg-5 mb-sm-5 mb-lg-0">
              <h2 className="text-color-dark font-weight-normal text-6 mb-2">
                Who <strong className="font-weight-extra-bold">We Are</strong>
              </h2>
              <p className="lead">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit massa
                enim. Nullam id varius nunc.{" "}
              </p>
              <p className="pe-5 me-5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Phasellus blandit massa enim. Nullam id varius nunc. Vivamus
                bibendum magna ex, et faucibus lacus venenatis eget
              </p>
            </div>
            <div className="col-sm-8 col-md-6 col-lg-4 offset-sm-4 offset-md-4 offset-lg-2 position-relative mt-sm-5">
              <img
                src="/assets/img/lazy.png"
                className="img-fluid position-relative mb-2"
                alt=""
              />
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default Index;
