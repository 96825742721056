import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

function Index() {
  return (
    <>
      <Helmet>
        <title>{"Jane anjani baten - Privacy"}</title>
        <meta name="description" content={"Privacy"} />
      </Helmet>
      <section className="page-header page-header-modern bg-color-grey page-header-md mb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12 align-self-center p-static order-2 text-center">
              <h1 className="text-dark font-weight-bold text-8">Privacy </h1>
            </div>
            <div className="col-md-12 align-self-center order-1">
              <ul className="breadcrumb d-block text-center">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Privacy</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container py-4">
        <div className="row">
          <div className="col">
            <div className="toggle toggle-primary m-0">
              <section className="toggle active">
                <a className="toggle-title ">
                  What Personal Information Do We Collect?
                </a>
                <div className="toggle-content">
                  <p>
                    We collect personal information about you when you interact
                    with us. The types of information we may ask for depends on
                    the nature of your interaction with us. This information may
                    include:
                    <ul>
                      <li>
                        <b> Communication information :</b> We may collect the
                        information you provide us when you communicate with us
                        for support, give us feedback, participate in our
                        events, or otherwise interact with us.
                      </li>
                      <li>
                        <b>Device information :</b> We may collect information
                        about your browser and device such as your IP address,
                        browser type, operating system type, unique device
                        identifier and log information.
                      </li>
                      <li>
                        <b>Analytics information :</b> We may collect
                        information about your usage of our Sites using tracking
                        snippets (eg. Google Analytics). These tools provide
                        aggregate insights, including information about which
                        pages were viewed, and for how long. These snippets
                        operate using cookies and other locally-stored
                        technologies. The information collected about your
                        activity on our Sites includes pages viewed and access
                        time.
                      </li>
                    </ul>
                  </p>
                </div>
              </section>

              <section className="toggle active">
                <a className="toggle-title ">
                  Why do we collect your personal information?
                </a>
                <div className="toggle-content">
                  <p>
                    We use your personal information for the following purposes:
                    <ul>
                      <li>For general business administration purposes.</li>
                      <li>
                        To fulfill purchases, by setting up and managing your
                        account on our Sites.
                      </li>
                      <li>
                        For customer support, to respond to support tickets and
                        help troubleshoot problems.
                      </li>
                      <li>To protect you and us against error.</li>
                      <li>To assess and manage operational risks.</li>
                      <li>
                        To conduct research and analysis for the development of
                        our products and services.
                      </li>
                      <li>
                        To maintain, improve and analyze our Sites and the
                        products we offer.
                      </li>
                      <li>
                        To communicate with you about existing and new services,
                        products, and offers by email, as permitted under
                        Canada’s Anti-Spam Legislation (CASL).
                      </li>
                      <li>
                        To customize your experience on our Sites to your
                        particular preferences or interests.
                      </li>
                    </ul>
                    We do not and will never sell your personal information to
                    others.
                  </p>
                </div>
              </section>

              <section className="toggle active">
                <a className="toggle-title ">
                  Who do we share your personal information with?
                </a>
                <div className="toggle-content">
                  <p>
                    For the purposes set out above, we may disclose your
                    personal information to:
                    <ul>
                      <li>Our employees, agents, and subsidiaries.</li>
                      <li>
                        Third-party service providers to process or handle
                        personal information on our behalf.
                      </li>
                      <li>
                        Government agencies and law enforcement or regulatory
                        authorities when required by law.
                      </li>
                      <li>Our insurers and external legal advisors.</li>
                      <li>
                        {" "}
                        In the event of a merger, acquisition sale or
                        reorganization, or the potential thereof, we may
                        disclose information about you to the extent the
                        personal information is necessary to carry on the
                        business or activity that was the object of the
                        transaction. In the event of a completed transaction,
                        notice will be given to affected users.
                      </li>
                    </ul>
                    We do not and will never sell your personal information to
                    others.
                  </p>
                </div>
              </section>

              <section className="toggle active">
                <a className="toggle-title ">
                  How long will we use, disclose and retain your personal
                  information?
                </a>
                <div className="toggle-content">
                  <p>
                    We will retain your personal information only for as long as
                    necessary to accomplish the identified purpose for which it
                    was collected, or as required by law. When your personal
                    information is no longer needed, or once we are no longer
                    required to retain it, we will erase it.
                  </p>
                </div>
              </section>

              <section className="toggle active">
                <a className="toggle-title">
                  How do we protect your personal information?
                </a>
                <div className="toggle-content">
                  <p>
                    We employ reasonable technical, administrative, and physical
                    safeguards and security measures to protect your personal
                    information from unauthorized access, use, disclosure,
                    copying, loss, misuse, alteration, modification or
                    destruction.
                  </p>
                </div>
              </section>

              <section className="toggle active">
                <a className="toggle-title ">Cookies</a>
                <div className="toggle-content">
                  <p>
                    We use cookies to enhance your experience on our website.
                    Cookies are small files that a site or its service provider
                    transfers to your computer's hard drive through your web
                    browser to enable the site's systems to recognize your
                    browser and capture and remember certain information.
                  </p>
                </div>
              </section>

              <section className="toggle active">
                <a className="toggle-title ">
                  Embedded content from other websites
                </a>
                <div className="toggle-content">
                  <p>
                    Articles on this site may include embedded content (e.g.
                    videos, images, articles, etc.). Embedded content from other
                    websites behaves in the exact same way as if the visitor has
                    visited the other website.
                  </p>
                  <p>
                    These websites may collect data about you, use cookies,
                    embed additional third-party tracking, and monitor your
                    interaction with that embedded content, including tracking
                    your interaction with the embedded content if you have an
                    account and are logged in to that website.
                  </p>
                </div>
              </section>

              <section className="toggle active">
                <a className="toggle-title ">Changes to this policy</a>
                <div className="toggle-content">
                  <p>
                    We may update our Privacy Policy from time to time. We ask
                    that you periodically check the date and review this Privacy
                    Policy for the latest information on our privacy practices.
                    If we update our Privacy, we will post the most recent
                    version on our Sites.
                  </p>
                </div>
              </section>

              <section className="toggle active">
                <a className="toggle-title ">Contacting us</a>
                <div className="toggle-content">
                  <p>
                    If you have any questions, concerns, or requests relating to
                    our handling of your personal information, please direct
                    them to the contact information listed below. We may need to
                    ask you for personal information to verify your identity.
                    <br />
                    By email:{" "}
                    <a href="mailto:dharmeshkashyap143@gmail.com">
                      dharmeshkashyap143@gmail.com{" "}
                    </a>
                  </p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
